import { useState } from "react";
import logo from "./logo-removebg-preview.png";
import "./App.css";
import Map from "./Map";

function App() {
  const [showMap, setShowMap] = useState(false);

  const handleMap = () => {
    if (!showMap) {
      setShowMap(true);
      window.scrollTo(0, 1000);
    } else setShowMap(false);
  };

  return (
    <div className="App">
      <div className="App-header">
        <img src={logo} className="" width="100%" alt="logo" />
        <p className="engineering">ENGINEERING SERVICES</p>
        <small>Since 1986</small>
      </div>

      <div className="info">
        <h3>CONTACT US</h3>
        <p>Michail Aggelou 24, 45332 Ioannina GR</p>
        <p>(+30) 26510 72089</p>
        <address>
          <p>
            <a
              className="email"
              rel="noreferrer"
              target="_blank"
              href="mailto:info@kplan-engineering.com"
            >
              info@kplan-engineering.com
            </a>
          </p>
        </address>
      </div>
      <div className="map">
        <div style={{ height: "30px" }}>
          <span onClick={handleMap} className="map-toggler">
            {!showMap ? "Show me the way!" : "Hide"}
          </span>
        </div>
        {showMap && <Map />}
      </div>
    </div>
  );
}

export default App;
